/** Shelf Life: Yearbook Ads **/

import React from "react";
import { gsap } from "gsap"
import { preloadImages, scaleFullscreen } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import Carousel from "../../components/media/carousel";
import SlideshowControllerSmall from "../../components/media/slideshow-controller-small";
import FullscreenVideo from "../../components/media/fullscreen-video";

let globalState, data, preloadData, slideshowData, autoAdvanceInterval, visibleAreaEdge;

class ShelfLifePage2 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[2];
    preloadData = globalState.assets[3];

    visibleAreaEdge = 1280 / 3 * 2 + 35;

    slideshowData = [
      {
        file: globalState.cdnUrl + data.slide1,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide3,
        fileType: 'image',
      },
      {
        file: globalState.cdnUrl + data.slide4,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide5,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide6,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide7,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide8,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide9,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide10,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide11,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide12,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide13,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide14,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide15,
        fileType: 'image'
      }
    ]


    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
    currentSlide: 1,
    autoAdvance: 6000,
    styles: {
      top: 0,
      left: 0
    }
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //Set autoAdvance
    this.setAutoAdvance();

    //position text
    positionText(this.refs.text1Ref, "right", "bottom", globalState.textPosition.rightBottom);

    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });

    //add page base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1); //VO

    //update volume of base track
    globalState.baseAudioRef.updateVolume(2, 60);

    //Update dimensions
    this.updateDimensions();



    //Add resize listener
    window.addEventListener("resize", this.updateDimensions);



    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.text2,
      globalState.cdnUrl + preloadData.text3,
      globalState.cdnUrl + preloadData.bgVideoPoster,
      globalState.cdnUrl + preloadData.divider,
      globalState.cdnUrl + preloadData.slide1,
      globalState.cdnUrl + preloadData.slide2,
      globalState.cdnUrl + preloadData.slide3,
      globalState.cdnUrl + preloadData.slide4,
      globalState.cdnUrl + preloadData.slide5,
      globalState.cdnUrl + preloadData.slide6,
      globalState.cdnUrl + preloadData.slide7,
      globalState.cdnUrl + preloadData.slide8,
      globalState.cdnUrl + preloadData.slide9,
      globalState.cdnUrl + preloadData.slide10,
      globalState.cdnUrl + preloadData.slide11,
      globalState.cdnUrl + preloadData.slide12,
      globalState.cdnUrl + preloadData.slide13,
      globalState.cdnUrl + preloadData.slide14,
      globalState.cdnUrl + preloadData.slide15,
      globalState.cdnUrl + preloadData.slide16,
      globalState.cdnUrl + preloadData.slide17,
      globalState.cdnUrl + preloadData.slide18,
      globalState.cdnUrl + preloadData.slide19,
      globalState.cdnUrl + preloadData.slide20,
      globalState.cdnUrl + preloadData.slide21,
      globalState.cdnUrl + preloadData.slide22,
      globalState.cdnUrl + preloadData.slide23,
      globalState.cdnUrl + preloadData.slide24,
      globalState.cdnUrl + preloadData.slide25,
      globalState.cdnUrl + preloadData.slide26,
      globalState.cdnUrl + preloadData.slide27,
      globalState.cdnUrl + preloadData.slide28,
      globalState.cdnUrl + preloadData.slide29,
      globalState.cdnUrl + preloadData.slide30,
      globalState.cdnUrl + preloadData.slide31,
      globalState.cdnUrl + preloadData.slide32,
      globalState.cdnUrl + preloadData.slide33,
      globalState.cdnUrl + preloadData.slide34,
      globalState.cdnUrl + preloadData.slide35,
      globalState.cdnUrl + preloadData.slide36,
      globalState.cdnUrl + preloadData.slide37,
      globalState.cdnUrl + preloadData.slide38,
      globalState.cdnUrl + preloadData.slide39,
      globalState.cdnUrl + preloadData.slide40,
      globalState.cdnUrl + preloadData.slide41,
      globalState.cdnUrl + preloadData.slide42,
      globalState.cdnUrl + preloadData.slide43,
      globalState.cdnUrl + preloadData.slide44,
      globalState.cdnUrl + preloadData.slide45,
      globalState.cdnUrl + preloadData.slide46,
      globalState.cdnUrl + preloadData.slide47,
    ], this.preloadCallback);

    //update the carousel 
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);
  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  componentDidUpdate() {
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);
  }


  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);

    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);

    //remove event listeners
    window.removeEventListener("resize", this.updateDimensions);

    //Clear AutoAdvanceInterval
    clearInterval(autoAdvanceInterval);
  }

  bgVideoEnded = () => {
    console.log("Bg Video ended");
    this.bgVideoRef.seekVideo(74);
  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.text1Ref, "right", "bottom", globalState.textPosition.rightBottom);


    } else {
      //hide text
      hideText(this.refs.text1Ref, "right");
    }
  }

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }

  updateDimensions = () => {
    console.log('updateDimensions');
    scaleFullscreen(this.refs.fullscreenBgRef, window.innerWidth, window.innerHeight, 1280, 760);

  }


  //Set AutoAdvance
  setAutoAdvance = () => {
    if (this.state.autoAdvance) {
      autoAdvanceInterval = setInterval(() => {
        let nextSlide = this.state.currentSlide + 1;

        if (nextSlide > slideshowData.length) {
          nextSlide = 1;
        }
        this.setState({ currentSlide: nextSlide });
      }, this.state.autoAdvance);
    }
  }

  //Stop AutoAdvance
  stopAutoAdvance = () => {
    this.setState({ autoAdvance: false });
    clearInterval(autoAdvanceInterval);
  }

  //Slideshow controls
  handleNextClick = () => {
    let nextSlide = this.state.currentSlide + 1;

    if (nextSlide > slideshowData.length) {
      nextSlide = 1;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }
  handleBackClick = () => {
    let nextSlide = this.state.currentSlide - 1;

    if (nextSlide < 1) {
      nextSlide = slideshowData.length;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }


  render() {

    return (

      <>

        <link rel="prefetch" href="/shelf-life/3" />
        {preloadData && (
        <link rel="preload" as="video" href={globalState.videoUrl + preloadData.bgVideo} />
        )}

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/shelf-life/3" prevLocation="/shelf-life/1"
          isTransitioning={this.isTransitioning} />

        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Shelf Life" />

          <div className="text-wrapper">
            <div className="text text--right-bottom" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "0", marginBottom: "30px" }} onClick={this.toggleTextDisplay} alt="Wayne Hryniuk left before Pine Point closed. For him, the end meant he would forever be missing a touchstone. In its place a bunch of loose ends." />
            </div>
          </div>

          <SlideshowControllerSmall xPos={"calc(100vw - 417px)"} yPos={"calc(100vh - 70px)"} handleNextClick={this.handleNextClick} handleBackClick={this.handleBackClick} state={this.state} totalSlides={slideshowData.length} />

          <div className="fullscreen-bg" ref="fullscreenBgRef" >
            <div className="carousel-wrapper" style={{top: 0, left: 0, position: 'absolute', width: visibleAreaEdge + 'px', height: '760px', overflow: 'hidden'}}>
              <Carousel ref="carouselRef" data={slideshowData} globalState={globalState} fullscreen={false} position={['0', '0']} size={["992px", "800px"]} state={this.state} />
            </div>
            {data.bgVideo ?
              <div className="video-wrapper" style={{ position: 'absolute', left: visibleAreaEdge + 'px', top: 0 }}>
                <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
                  poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} fullscreenClass={''}  loop={false} ended={this.bgVideoEnded} ref={div => this.bgVideoRef = div} globalState={globalState} /></div> : <div />
            }
            <img src={globalState.cdnUrl + data.divider} alt="" style={{position: 'absolute', top: 0, left: visibleAreaEdge-8 + 'px', height: '770px'}}/>

          </div>


        </div>
        )}
      </>
    );
  }


};

export default ShelfLifePage2
